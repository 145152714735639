import React, { useState, useEffect, useRef, useContext } from 'react';
import { ChatContext } from '../../../Context/ChatContext';
import './Chat.css';
import { FileContext } from '../../../Context/FileContext';
import useHandleSendMessage from '../../../Hooks/useHandleSendMessage';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthContext';
import useUserProfile from '../../../Hooks/useUserProfile';


function Chat() {
    const { allMessages, setAllMessages, messageInput, setMessageInput, error, setError, isLoading, setIsLoading, surveyData , setSurveyData, showFeedback, setShowFeedback} = useContext(ChatContext);
    const { file, setFile, filePreview, setFilePreview, fileError, showUploadPrompt, setShowUploadPrompt, handleUploadClick, handleFileChange}  = useContext(FileContext);
    const [copiedMessageId, setCopiedMessageId] = useState(null);
    const [speakingMessageId, setSpeakingMessageId] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false); 
    const [prevMessagesLength, setPrevMessagesLength] = useState(0);
    const chatboxRef = useRef();
    const [loadingChatHistory, setLoadingChatHistory] = useState(true);
    const location = useLocation();
    const {isLoggedIn} = useContext(AuthContext);
    const { profileData } = useUserProfile(isLoggedIn);
    const [receivedQuestion, setReceivedQuestion] = useState(false);

    const { handleSendMessage } = useHandleSendMessage({
        allMessages, setAllMessages,
        messageInput,setMessageInput,
        filePreview, setFilePreview,
        error, setError,
        file, setFile,
        fileError,
        showUploadPrompt, setShowUploadPrompt,
        showFeedback, setShowFeedback,
        isLoading, setIsLoading,
        surveyData
      });

    const fetchChatHistory = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/chat-history`, {
                method: 'GET',
                credentials: 'include',  
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }
            const data = await response.json();
            setAllMessages(data.chat_history || []);
            
        } catch (error) {
            console.error('Error fetching chat history:', error);
            
        } finally {
            setLoadingChatHistory(false); 
        }
    };

    useEffect(() => {
        fetchChatHistory();
    }, []);

    // For chat history time
    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }

    // Update feedback visibility whenever messages change
    useEffect(() => {
        const newShowFeedback = {};

        allMessages.forEach((msg, index) => {
            // Set feedback visibility for each message: 
            // Hide feedback for the first message, show for others
            // *Removed this due to feedback*
            // newShowFeedback[index] = index !== 1;

            newShowFeedback[index] = true;
        });

        setShowFeedback(newShowFeedback);

        if (allMessages.length == 0) {
            setShowUploadPrompt(false);
        }

    }, [allMessages]);

    useEffect(() => {
        const storedSurveyData = localStorage.getItem('surveyData');
        if (storedSurveyData) {
            setSurveyData(JSON.parse(storedSurveyData));
        }
    
}, [setSurveyData]);

    const handleLikeClick = async (msg, msgId, index) => {
        // Update UI with feedback
        const updatedMessages = allMessages.map((message, i) => {
            if (i === index) {
              return { ...message, liked: !message.liked, disliked: message.liked ? message.disliked : false };
            }
            return message;
          });
          setAllMessages(updatedMessages);

          console.log("liked?", !msg.liked);

          // Send feedback to backend to update feedback in database
            try {
                await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/feedback`, {
                    messageId: msgId,
                    liked: !msg.liked ? 1 : 0
                });
            } catch (error) {
                console.error('Error sending like feedback:', error);
            }  
        
    };

    const handleDislikeClick = async (msg, msgId, index) => {
        // Update UI with feedback
        const updatedMessages = allMessages.map((message, i) => {
            if (i === index) {
              return { ...message, disliked: !message.disliked, liked: message.disliked ? false : message.liked };
            }
            return message;
          });
          setAllMessages(updatedMessages);

        console.log("disliked?", !msg.disliked);

          // Send feedback to backend to update feedback in database
            try {
                await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/feedback`, {
                    messageId: msgId,
                    disliked: !msg.disliked ? 1 : 0
                });
            } catch (error) {
                console.error('Error sending dislike feedback:', error);
            }  
    };

    const handleCopyClick = (text, messageId) => {
          navigator.clipboard.writeText(text)
            .then(() => {
              setCopiedMessageId(messageId);

               // Revert back to the copy icon after 2 seconds
                setTimeout(() => {
                    setCopiedMessageId(null);
                }, 2000);
            })
            .catch(err => {
              console.error('Error copying to clipboard:', err);
            });
        if (!text) {
            console.warn('No AI response found to copy.');
          }
    };

    const handleSpeakClick = (text, messageId) => {
        if (isSpeaking) {
            // If already speaking, stop the current speech
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
            setSpeakingMessageId(null);
          } else {
            // Start speaking
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.onend = () => setIsSpeaking(false); // Reset state when speech ends
            window.speechSynthesis.speak(utterance);
            setIsSpeaking(true);
            setSpeakingMessageId(messageId);
          }
    };

    const handleRegenerateClick = (e, aiMessageIndex) => {
        const userMessage =  allMessages[aiMessageIndex - 1].content;
        console.log("user msg to regenerate", userMessage);

          // Call the handleSendMessage function to resend the message
          handleSendMessage(e, userMessage);
    }

    const scrollToBottom = () => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    };

    // Scroll to the bottom of the chatbox whenever the messages array changes length
    useEffect(() => {
        scrollToBottom();
    }, [allMessages, fileError]);

    return (
        <div className="chatbox" >
        {allMessages.map((msg, index) => ( // Map messages based on role
            <div key={index}>
            <div className="reminder-container">
                {msg.tokensLeft <= 6 &&  index > 0 && allMessages[index - 1].role !== 'user' && (      
                    <div className="tokens-reminder"> 
                        { (msg.tokensLeft <= 0) ? (
                            "You have reached the tokens limit."
                        ) : (
                            `You have ${msg.tokensLeft - 1} tokens left.`
                        )}
                    </div>
                )}
            </div>

            <div key={index} className={`message ${msg.role}`} >
                {msg.role === 'assistant' ? (
                    <>
                    <div className='photo-time'>
                        <img src="/chatbot-profile-photo.png" className="chat-photos" />
                        {/* <div className="timestamp">
                            {msg.time}
                         </div> */}
                         <div className="timestamp" dangerouslySetInnerHTML={{ __html: msg.time }} />
                    </div>
                        <div className="message-bubble assistant">
                             <div className="assistant-content" dangerouslySetInnerHTML={{ __html: msg.content }}/>

                        {showFeedback[index] && ( 
                            <div className="assistant-buttons">
                            <div className="assistant-feedback-buttons">

                                <span className="helpful-text">Was this helpful?</span>

                                <button className="like-button" onClick={() => handleLikeClick(msg, msg.id, index)} disabled={msg.disliked}>
                                    {msg.liked ? (
                                            <img src="/liked.png" className="like-image" alt="like icon" />
                                    ) : (
                                        <img src="/like.png" className="like-image" alt="like icon"/>
                                    )}
                                    
                                </button>
                                <button className="unlike-button" onClick={() => handleDislikeClick(msg, msg.id, index)} disabled={msg.liked}>
                                {msg.disliked ? (
                                        <img src="/unliked.png"className="unlike-image" alt="unlike icon"/>
                                    ) : (
                                        <img src="/unlike.png"className="unlike-image" alt="unlike icon"/>
                                    )}
                                </button>
                            </div> 

                            <div className="assistant-other-buttons"> 
                                <button className="sound-button" onClick={() => handleSpeakClick(msg.content, index)}>
                                {isSpeaking && speakingMessageId == index  ? (
                                        <img src="/stop.png" className="sound-image" alt="speaker icon" />
                                        ) : (
                                        <img src="/speaker.png" className="sound-image" alt="speaker icon" />
                                        )}
                                </button>
                                <button className="copy-button" onClick={() => handleCopyClick(msg.content , index)}>
                                    {copiedMessageId === index ? (
                                        <img src="/check.png" className="checkmark-image" alt="checkmark icon" />
                                        ) : (
                                        <img src="/copy.png" className="copy-image" alt="copy icon" />
                                        )}
                                </button>
                                <button className="refresh-button">
                                    <img src="/refresh.png" className="refresh-image" alt="refresh icon" onClick={(e) => handleRegenerateClick(e , index)}/>
                                </button>
                            </div>
                            </div>
                            )}

                        </div>

                    </>
                ) : (
                    <>
                        {msg.content && (
                            <div className="message user">
                                <div className="message-bubble user">
                                    {msg.content}
                    
                                </div>
                                <div className="photo-time">
                                    {profileData &&  <img src={profileData} className="chat-photos" />}
                                    {!profileData && <img src="/user-profile-photo.png" className="chat-photos" />}
                                    { msg.time.includes('<br>') ? (
                                        <div className="timestamp" dangerouslySetInnerHTML={{ __html: msg.time }} />
                                    ) : (
                                        <div className="timestamp">
                                                {msg.time}
                                        </div> 
                                    )}                               
                                </div>
                            </div>
                        )}

                        {msg.file && (
                        <div className='message-file'>
                            <div className="message-bubble-file">
                            {msg.file.startsWith('blob:') ? (
                                <img src={msg.file} className="file-preview"/>
                            ) : (
                                
                                <div className="file-container">
                                    <img src="/file-icon-p.png" className="file-icon"/>
                                    <p className="file-name-b">{msg.file}</p>
                                </div>
                            )} 
                            </div>
                        
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        )}
                    </>
                )}

            </div>
            </div>
        ))}

        {showUploadPrompt && !isLoading && !error  && (
            <div className="message assistant" >
                <div className="chat-photos" />
                <div className="upload-bubble">
                    <p className="upload-bubble-text">If you have a policy file to upload, upload it here.</p>

                    <button className="upload-button" type="submit">
                        <img src="/upload.png" className="upload-image" alt="Upload Icon" onClick={handleUploadClick}/>
                        <input  id="file-input" 
                                type="file"  
                                onChange={(e) => handleFileChange(e)}/>
                    </button>
                </div>
            </div>
        )}

         {isLoading && (
        <div className="reminder-container">
            <div className="message assistant">
                <img src="/chatbot-profile-photo.png" className="chat-photos" />
                <div className="message-bubble-n assistant">
                    <span className="bouncing-dots">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </span>
                    <p>Generating Response</p>
                </div>
            </div>
        </div>
        )}
        {error && (
        <div className="reminder-container">
            <div className="message assistant" >
                <img src="/chatbot-profile-photo.png" className="chat-photos" />
                <div className="message-bubble-n assistant">
                    <p>{error}</p>
                </div>
            </div>
        </div>
        )} 

        {fileError && (
        <div className="reminder-container">
            <div className="message assistant" >
                <img src="/chatbot-profile-photo.png" className="chat-photos" />
                <div className="message-bubble-n assistant">
                    <p>{fileError}</p>
                </div>
            </div>
        </div>
        )} 

        <div ref={chatboxRef} />
    </div>
    );
}

export default Chat;