// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tokens{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 40px;
    position: relative;
    font-size: 18px;
 } 

 @media (max-width: 700px){
    .tokens{
        font-size: 15px;
        margin-right: 28px;
     }
}

.tokens-button{
    border: none;
    background: transparent;
}  
.tokens-image{
    width: 25px;
    height: 25px;
    cursor: pointer;
}  

@media (max-width: 700px){
    .tokens-image{
        width: 22px;
        height: 22px;
     }
}

.tokens-text{
    text-align: end;
    color: #000000;
    margin-top: 2.8px;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
}
.tokens-popup {
    position: absolute;
    right: 0;
    margin-right: 222px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: start;
    border-radius: 10px;
    padding: 10px;
    min-width: 50px;
    z-index: 1000; 
}

@media (max-width: 700px){
    .tokens-popup{
        margin-right: 185px;
        margin-left: 5px;
        font-size: 15px;
     }
}

.tokens-popup p {
    margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/ChatbotComponents/Tokens/Tokens.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;CAClB;;CAEA;IACG;QACI,eAAe;QACf,kBAAkB;KACrB;AACL;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;KACf;AACL;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;IACxC,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,aAAa;AACjB;;AAEA;IACI;QACI,mBAAmB;QACnB,gBAAgB;QAChB,eAAe;KAClB;AACL;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".tokens{\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 10px;\n    margin-right: 40px;\n    position: relative;\n    font-size: 18px;\n } \n\n @media (max-width: 700px){\n    .tokens{\n        font-size: 15px;\n        margin-right: 28px;\n     }\n}\n\n.tokens-button{\n    border: none;\n    background: transparent;\n}  \n.tokens-image{\n    width: 25px;\n    height: 25px;\n    cursor: pointer;\n}  \n\n@media (max-width: 700px){\n    .tokens-image{\n        width: 22px;\n        height: 22px;\n     }\n}\n\n.tokens-text{\n    text-align: end;\n    color: #000000;\n    margin-top: 2.8px;\n    font-family: 'Lato', sans-serif;\n    margin-top: 5px;\n}\n.tokens-popup {\n    position: absolute;\n    right: 0;\n    margin-right: 222px;\n    background-color: #ffffff;\n    border: 1px solid #ccc;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    text-align: start;\n    border-radius: 10px;\n    padding: 10px;\n    min-width: 50px;\n    z-index: 1000; \n}\n\n@media (max-width: 700px){\n    .tokens-popup{\n        margin-right: 185px;\n        margin-left: 5px;\n        font-size: 15px;\n     }\n}\n\n.tokens-popup p {\n    margin: 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
