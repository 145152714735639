import React from "react";
import './Footer.css';

function Footer() {
    return (
      <div className="footer">
        <div className="footer-content">
            {/* <div className="footer-logo"/>
                <div className="logo-placeholder" /> */}
                <img src="favicon.ico" className="footer-logo"/>

            <nav className="footer-links">
                <div className="footer-links-list">
                    <a href="/" className="footer-link">Home</a>
                    <a href="https://careerbenchmark.com" className="footer-link">Smart Career Benchmark</a>
                    <a href="/" className="footer-link">Augmented Mentorship</a>
                    <a href="/" className="footer-link">Enterprise Solution</a>
                </div>
                <div className="legal-links-list">
                    <a href="/terms-and-conditions" className="footer-link">Terms and Conditions</a>
                    <a href="/privacy-policy" className="footer-link">Policies</a>
                    <a href="/privacy-policy#contact" className="footer-link">Contact</a>
                </div>
            </nav>  

            <div className="newsletter-signup">
                <h4 className="newsletter-title">Join Our Newsletter</h4>
                    <p className="newsletter-description"> Get the latest information from Mentelia</p>
            <form className="newsletter-form">
                <label htmlFor="emailInput" className="visually-hidden">Enter your email address</label>
                    <input
                    className="email-input"
                    type="email"
                    id="emailInput"
                    placeholder="Enter your email address"
                    aria-label="Enter your email address"
                    />
            <button className="signup-button">Sign Up</button>
          </form>
        </div>
       </div><p className="version">Version 0.2.4</p>
      </div>
  
  
    );
  }
  
  export default Footer;